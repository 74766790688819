import React from "react"
import { Link } from "gatsby"
import Layout from "../components/common/layout/layout"
import NavigationLite from "../components/common/navigationLite/navigationLite"
import { FeatureTitle, ButtonTitle } from "./careers"
import { StyledSection } from "./access"

export default function PrivacyPolicy() {
  return (
    <Layout>
      <NavigationLite />
      <StyledSection>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <FeatureTitle>Privacy Policy</FeatureTitle>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Link style={{ textDecoration: "none" }} to="/">
            <ButtonTitle>Back</ButtonTitle>
          </Link>
        </div>
        <main
          style={{
            maxWidth: 800,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            margin: "auto",
          }}
        >
          <section>
            <h4>Effective Date: January 1, 2024</h4>
            <h4>Information Collection</h4>
            <p>
              We collect information that you provide directly to us when you
              use our services. This may include personal information such as
              your name, email address, phone number, and any other information
              you choose to provide.
            </p>
            <p>
              <strong>Why We Collect Your Phone Number:</strong> We collect your
              phone number to facilitate communication with you and provide
              personalized services. Your phone number will only be used for
              purposes you explicitly consent to.
            </p>
            <p>
              <strong>Consent Process:</strong> Before collecting your phone
              number, we will ask for your explicit consent to receive
              communications from us, including SMS messages. You have the right
              to opt-out at any time.
            </p>
          </section>
          <section>
            <h4>Use of Information</h4>
            <p>
              We use the information we collect to provide, maintain, and
              improve our services, to communicate with you, and to personalize
              your experience. We do not use your mobile information for
              marketing or promotional purposes.
            </p>
          </section>
          <section>
            <h4>Sharing of Information</h4>
            <p>
              We do not share your mobile information with third parties or
              affiliates for their marketing or promotional purposes. However,
              we may share information with subcontractors who provide support
              services to us, such as customer service, to the extent necessary
              for them to provide those services.
            </p>
            <p>
              We may also share your information as required by law, to protect
              our rights or property, or in the event of a merger, acquisition,
              or sale of all or a portion of our assets.
            </p>
          </section>
          <section>
            <h4>Exclusion of Text Messaging Originator Opt-In Data</h4>
            <p>
              We do not share text messaging originator opt-in data and consent
              with any third parties. This information is excluded from all use
              case categories and will not be shared under any circumstances.
            </p>
          </section>
          <section>
            <h4>Your Choices</h4>
            <p>
              You have the right to opt out of receiving communications from us
              by following the unsubscribe instructions in those communications.
              You may also have rights to access, correct, delete, or restrict
              the use of your personal information.
            </p>
          </section>
          <section>
            <h4>Changes to This Privacy Policy</h4>
            <p>
              We may update this Privacy Policy from time to time. We will
              notify you of any changes by posting the new Privacy Policy on
              this page and updating the "Effective Date" at the top.
            </p>
          </section>
          <section>
            <h4>Contact Us</h4>
            <p>
              If you have any questions about this Privacy Policy, please
              contact us at{" "}
              <a href="mailto:operations@mobilenurse.io">
                operations@mobilenurse.io
              </a>
              .
            </p>
          </section>
        </main>
      </StyledSection>
    </Layout>
  )
}
