import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Layout from "../components/common/layout/layout"
import NavigationLite from "../components/common/navigationLite/navigationLite"
import Footer from "../components/sections/footer"

import { Section } from "../../src/components/global"

export default function Access() {
  return (
    <Layout>
      <NavigationLite />
      <StyledSection>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <FeatureTitle>Tell us more about you</FeatureTitle>
        </div>
        <FormGroup>
          <div>
            <form
              action="https://getform.io/f/ca669046-d22e-433e-aaf8-6ca47cf50e50"
              method="POST"
            >
              <Label>
                Name
                <Input type="text" name="name" id="name" />
              </Label>
              <Label>
                Phone
                <Input type="tel" name="phone" id="phone" />
              </Label>
              <Label>
                Email
                <Input type="email" name="email" id="email" />
              </Label>
              <Label for="cars">What best describes your role?</Label>
              <Select style={{ width: "100%" }} name="cars" id="cars">
                <option value="Liscensed Agent">
                  Licensed insurance representative or CFP
                </option>
                <option value="Operations">
                  Operational role for a licensed insurance agent/CFP
                </option>
                <option value="other">Other</option>
              </Select>
              <Label>
                Message
                <TextArea name="message" id="message" rows="3" />
              </Label>
              <SubmitButton type="submit">Submit</SubmitButton>
            </form>
          </div>
        </FormGroup>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Link style={{ textDecoration: "none" }} to="/">
            <ButtonTitle>Back</ButtonTitle>
          </Link>
        </div>
      </StyledSection>
      <Footer />
    </Layout>
  )
}

const Select = styled.select`
  padding: 0.5em;
  color: ${(props) => props.theme.color.primary};
  background: white;
  border-radius: 3px;
  border: 1px solid #ccc;
  width: 100%;
  margin-bottom: 1.5em;
`

const FeatureTitle = styled.h4`
  color: ${(props) => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
`

export const StyledSection = styled(Section)`
  background-color: ${(props) => props.theme.color.background.light};
  clip-path: polygon(0 0, 100% 14%, 100% 100%, 0% 100%);
`

export const FormGroup = styled.div`
  color: green;
  display: block;
  margin: 50px auto;
  max-width: 450px;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    max-width: 90%;
  }
`

export const Label = styled.label`
  margin-bottom: 0.5em;
  color: ${(props) => props.theme.color.primary};
  display: block;
  font-size: 18px;
  font-weight: 500;
`

export const Input = styled.input`
  padding: 0.5em;
  color: ${(props) => props.theme.color.primary};
  background: white;
  border-radius: 3px;
  border: 1px solid #ccc;
  width: 100%;
  margin-bottom: 1.5em;
`

export const TextArea = styled.textarea`
  padding: 0.5em;
  color: ${(props) => props.theme.color.primary};
  background: white;
  border-radius: 3px;
  border: 1px solid #ccc;
  width: 100%;
  margin-bottom: 1.5em;
`
const SubmitButton = styled.button`
  font-weight: 700;
  font-size: 16px;
  color: white;
  letter-spacing: 1px;
  height: 50px;
  display: block;
  text-transform: uppercase;
  cursor: pointer;
  width: 100%;
  white-space: nowrap;
  text-decoration: none;
  background: ${(props) => props.theme.color.secondary};
  border-radius: 4px;
  padding: 0px 40px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
  @media (max-width: ${(props) => props.theme.screen.md}) {
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    margin-left: 0;
  }
`

export const Message = styled.label`
  margin-bottom: 0.5em;
  color: palevioletred;
  display: block;
`

const ButtonTitle = styled.span`
  text-decoration: none;
  color: ${(props) => props.theme.color.primary};
`
